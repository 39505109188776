import React, { useState, useEffect } from "react";
import {
  RealmAppProvider,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";
import OnboardingRedirector from "../../../components/facilitator/onboarding_redirector";
import DashboardNav from "../../../components/navbar";
import { FAC_NAVBAR_LINKS } from "../../../global";

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DashboardNav
            link="/facilitator/dashboard"
            menuItems={FAC_NAVBAR_LINKS}
            settingsLink="/facilitator/dashboard/settings"
          />
        </div>
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
